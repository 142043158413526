// xmlUtils.js
export const generateXml = (formData) => {
    let xmlString = '<?xml version="1.0" encoding="windows-1252"?>\n';
    xmlString += '<DOC_ARQ xmlns="urn:audtanual">\n';
    xmlString += `  <COD_DOC>${formData.codDoc || ''}</COD_DOC>\n`;
    xmlString += '  <INFORM_AUDIT>\n';
    xmlString += '    <DS_INFORM>\n';
    xmlString += `      <ANO_BASE>${formData.anoBase || ''}</ANO_BASE>\n`;
    xmlString += `      <DT_GERAC_ARQ>${formData.dtGeracArq || ''}</DT_GERAC_ARQ>\n`;
    xmlString += `      <MOTIVO_ENTREG>${formData.motivoEntreg || ''}</MOTIVO_ENTREG>\n`;
    xmlString += '    </DS_INFORM>\n';
    xmlString += '    <DS_AUDIT>\n';
    xmlString += `      <TP_PF_PJ>${formData.tpPfPj || ''}</TP_PF_PJ>\n`;
    xmlString += `      <NR_PF_PJ>${formData.nrPfPj || ''}</NR_PF_PJ>\n`;
    xmlString += `      <CD_CVM>${formData.cdCvm || ''}</CD_CVM>\n`;
    xmlString += `      <DS_NOME>${formData.dsNome || ''}</DS_NOME>\n`;
    xmlString += `      <DS_EMAIL>${formData.dsEmail || ''}</DS_EMAIL>\n`;
    xmlString += '    </DS_AUDIT>\n';
    xmlString += '    <LISTA_ENDER>\n';
    (formData.enderecos || []).forEach((endereco) => {
      xmlString += '      <ENDER>\n';
      xmlString += `        <TP_ENDER>${endereco.tpEnder || ''}</TP_ENDER>\n`;
      xmlString += `        <NM_FILIAL>${endereco.nmFilial || ''}</NM_FILIAL>\n`;
      xmlString += `        <NM_LOGRAD>${endereco.nmLograd || ''}</NM_LOGRAD>\n`;
      xmlString += `        <DS_COMPL>${endereco.dsCompl || ''}</DS_COMPL>\n`;
      xmlString += `        <DS_BAIRRO>${endereco.dsBairro || ''}</DS_BAIRRO>\n`;
      xmlString += `        <CD_MUN>${endereco.cdMun || ''}</CD_MUN>\n`;
      xmlString += `        <NR_CEP>${endereco.nrCep || ''}</NR_CEP>\n`;
      xmlString += `        <NR_DDD_TEL>${endereco.nrDddTel || ''}</NR_DDD_TEL>\n`;
      xmlString += `        <NR_TEL>${endereco.nrTel || ''}</NR_TEL>\n`;
      xmlString += `        <NR_DDD_FAX>${endereco.nrDddFax || ''}</NR_DDD_FAX>\n`;
      xmlString += `        <NR_FAX>${endereco.nrFax || ''}</NR_FAX>\n`;
      xmlString += '      </ENDER>\n';
    });
    xmlString += '    </LISTA_ENDER>\n';
    xmlString += '    <LISTA_RESP>\n';
    (formData.responsaveis || []).forEach((responsavel) => {
      xmlString += '      <RESP>\n';
      xmlString += `        <NR_PF>${responsavel.nrPf || ''}</NR_PF>\n`;
      xmlString += `        <DS_NOME>${responsavel.dsNome || ''}</DS_NOME>\n`;
      xmlString += `        <DS_EMAIL>${responsavel.dsEmail || ''}</DS_EMAIL>\n`;
      xmlString += '      </RESP>\n';
    });
    xmlString += '    </LISTA_RESP>\n';
    xmlString += '    <LISTA_ENTID_AUDITA>\n';
    (formData.entidadesAuditadas || []).forEach((entidade) => {
      xmlString += '      <ENTID_AUDITA>\n';
      xmlString += `        <TP_ENTID>${entidade.tpEntid || ''}</TP_ENTID>\n`;
      xmlString += `        <NR_PJ>${entidade.nrPj || ''}</NR_PJ>\n`;
      xmlString += `        <DS_NOME>${entidade.dsNome || ''}</DS_NOME>\n`;
      xmlString += `        <DT_CONTR>${entidade.dtContr || ''}</DT_CONTR>\n`;
      xmlString += `        <PR_FATURA>${entidade.prFatura || ''}</PR_FATURA>\n`;
      xmlString += '      </ENTID_AUDITA>\n';
    });
    xmlString += '    </LISTA_ENTID_AUDITA>\n';
    xmlString += `    <QT_OUTRA_ENTID_AUDITA>${formData.qtOutraEntidAudita || ''}</QT_OUTRA_ENTID_AUDITA>\n`;
    xmlString += '    <FATURA_ANUAL>\n';
    xmlString += `      <VL_AUDIT>${formData.vlAudit || ''}</VL_AUDIT>\n`;
    xmlString += `      <PR_AUDIT>${formData.prAudit || ''}</PR_AUDIT>\n`;
    xmlString += `      <HH_AUDIT>${formData.hhAudit || ''}</HH_AUDIT>\n`;
    xmlString += '    </FATURA_ANUAL>\n';
    xmlString += `    <DS_CRITER_HONOR>${formData.dsCriterHon || ''}</DS_CRITER_HONOR>\n`;
    xmlString += '    <RH>\n';
    xmlString += `      <QT_SOCIOS>${formData.qtSocios || ''}</QT_SOCIOS>\n`;
    xmlString += `      <QT_FUNC_TECNO>${formData.qtFuncTecno || ''}</QT_FUNC_TECNO>\n`;
    xmlString += '    </RH>\n';
    xmlString += '    <LISTA_ENTID_COLIG>\n';
    (formData.entidadesColigadas || []).forEach((entidade) => {
      xmlString += '      <ENTID_COLIG>\n';
      xmlString += `        <ST_ESTR>${entidade.stEstr || ''}</ST_ESTR>\n`;
      xmlString += `        <NR_PJ>${entidade.nrPj || ''}</NR_PJ>\n`;
      xmlString += `        <DS_NOME>${entidade.dsNome || ''}</DS_NOME>\n`;
      xmlString += `        <DS_SETOR_ATIVD>${entidade.dsSetorAtivd || ''}</DS_SETOR_ATIVD>\n`;
      xmlString += '      </ENTID_COLIG>\n';
    });
    xmlString += '    </LISTA_ENTID_COLIG>\n';
    xmlString += '  </INFORM_AUDIT>\n';
    xmlString += '</DOC_ARQ>\n';
    return xmlString;
  };
  
  export const parseXmlToFormData = (xmlContent, defaultFormData) => {
    try {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlContent, "text/xml");
      
      const getXmlValue = (doc, tagName, parentTag = null) => {
        let element;
        if (parentTag) {
          const parent = doc.getElementsByTagName(parentTag)[0];
          if (parent) {
            element = parent.getElementsByTagName(tagName)[0];
          }
        } else {
          element = doc.getElementsByTagName(tagName)[0];
        }
        return element ? element.textContent : '';
      };
      
      const getElementValue = (element, tagName) => {
        const childElement = element.getElementsByTagName(tagName)[0];
        return childElement ? childElement.textContent : '';
      };
      
      const newFormData = { ...defaultFormData };
      
      // Dados básicos
      newFormData.codDoc = getXmlValue(xmlDoc, 'COD_DOC') || '';
      newFormData.anoBase = getXmlValue(xmlDoc, 'ANO_BASE') || '';
      newFormData.dtGeracArq = getXmlValue(xmlDoc, 'DT_GERAC_ARQ') || '';
      newFormData.motivoEntreg = getXmlValue(xmlDoc, 'MOTIVO_ENTREG') || '';
      newFormData.tpPfPj = getXmlValue(xmlDoc, 'TP_PF_PJ') || '';
      newFormData.nrPfPj = getXmlValue(xmlDoc, 'NR_PF_PJ') || '';
      newFormData.cdCvm = getXmlValue(xmlDoc, 'CD_CVM') || '';
      newFormData.dsNome = getXmlValue(xmlDoc, 'DS_NOME', 'DS_AUDIT') || '';
      newFormData.dsEmail = getXmlValue(xmlDoc, 'DS_EMAIL', 'DS_AUDIT') || '';
      
      // Endereços
      const enderElements = xmlDoc.getElementsByTagName('ENDER');
      if (enderElements.length > 0) {
        newFormData.enderecos = [];
        for (let i = 0; i < enderElements.length; i++) {
          const enderElement = enderElements[i];
          newFormData.enderecos.push({
            tpEnder: getElementValue(enderElement, 'TP_ENDER') || '',
            nmFilial: getElementValue(enderElement, 'NM_FILIAL') || '',
            nmLograd: getElementValue(enderElement, 'NM_LOGRAD') || '',
            dsCompl: getElementValue(enderElement, 'DS_COMPL') || '',
            dsBairro: getElementValue(enderElement, 'DS_BAIRRO') || '',
            cdMun: getElementValue(enderElement, 'CD_MUN') || '',
            nrCep: getElementValue(enderElement, 'NR_CEP') || '',
            nrDddTel: getElementValue(enderElement, 'NR_DDD_TEL') || '',
            nrTel: getElementValue(enderElement, 'NR_TEL') || '',
            nrDddFax: getElementValue(enderElement, 'NR_DDD_FAX') || '',
            nrFax: getElementValue(enderElement, 'NR_FAX') || '',
          });
        }
      }
      
      // Responsáveis
      const respElements = xmlDoc.getElementsByTagName('RESP');
      if (respElements.length > 0) {
        newFormData.responsaveis = [];
        for (let i = 0; i < respElements.length; i++) {
          const respElement = respElements[i];
          newFormData.responsaveis.push({
            nrPf: getElementValue(respElement, 'NR_PF') || '',
            dsNome: getElementValue(respElement, 'DS_NOME') || '',
            dsEmail: getElementValue(respElement, 'DS_EMAIL') || '',
          });
        }
      }
      
      // Entidades Auditadas
      const entidAuditaElements = xmlDoc.getElementsByTagName('ENTID_AUDITA');
      if (entidAuditaElements.length > 0) {
        newFormData.entidadesAuditadas = [];
        for (let i = 0; i < entidAuditaElements.length; i++) {
          const entidElement = entidAuditaElements[i];
          newFormData.entidadesAuditadas.push({
            tpEntid: getElementValue(entidElement, 'TP_ENTID') || '',
            nrPj: getElementValue(entidElement, 'NR_PJ') || '',
            dsNome: getElementValue(entidElement, 'DS_NOME') || '',
            dtContr: getElementValue(entidElement, 'DT_CONTR') || '',
            prFatura: getElementValue(entidElement, 'PR_FATURA') || '',
          });
        }
      }
      
      // Outros campos
      newFormData.qtOutraEntidAudita = getXmlValue(xmlDoc, 'QT_OUTRA_ENTID_AUDITA') || '';
      newFormData.vlAudit = getXmlValue(xmlDoc, 'VL_AUDIT') || '';
      newFormData.prAudit = getXmlValue(xmlDoc, 'PR_AUDIT') || '';
      newFormData.hhAudit = getXmlValue(xmlDoc, 'HH_AUDIT') || '';
      newFormData.dsCriterHon = getXmlValue(xmlDoc, 'DS_CRITER_HONOR') || '';
      newFormData.qtSocios = getXmlValue(xmlDoc, 'QT_SOCIOS') || '';
      newFormData.qtFuncTecno = getXmlValue(xmlDoc, 'QT_FUNC_TECNO') || '';
      
      // Entidades Coligadas
      const entidColigElements = xmlDoc.getElementsByTagName('ENTID_COLIG');
      if (entidColigElements.length > 0) {
        newFormData.entidadesColigadas = [];
        for (let i = 0; i < entidColigElements.length; i++) {
          const entidElement = entidColigElements[i];
          newFormData.entidadesColigadas.push({
            stEstr: getElementValue(entidElement, 'ST_ESTR') || '',
            nrPj: getElementValue(entidElement, 'NR_PJ') || '',
            dsNome: getElementValue(entidElement, 'DS_NOME') || '',
            dsSetorAtivd: getElementValue(entidElement, 'DS_SETOR_ATIVD') || '',
          });
        }
      }
      
      return newFormData;
    } catch (error) {
      console.error('Erro ao analisar XML:', error);
      throw error;
    }
  };
  
  export const saveXmlFile = (formData) => {
    // Gera o XML a partir dos dados do formulário
    const xmlString = generateXml(formData);
  
    // Obtém a data e hora atual para formatar o nome do arquivo
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Adiciona zero à esquerda
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
  
    // Formata o nome do arquivo no formato desejado
    const fileName = `Formulario-CVM-${year}${month}${day}-${hours}${minutes}.xml`;
  
    // Cria o arquivo XML e inicia o download
    const blob = new Blob([xmlString], { type: 'text/xml' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  
    return true; // Retorna sucesso
  };
  
  