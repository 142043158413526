import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box
} from '@mui/material';

const LoadXmlDialog = ({ open, onClose, onLoad }) => {
  const fileInputRef = React.useRef(null);
  
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const xmlContent = e.target.result;
        onLoad(xmlContent);
      };
      reader.readAsText(file);
    }
  };
  
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Carregar Formulário Salvo</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Você deseja carregar um arquivo XML previamente salvo para continuar o preenchimento?
        </Typography>
        <Box sx={{ mt: 2 }}>
          <input
            type="file"
            ref={fileInputRef}
            onChange={handleFileChange}
            accept=".xml"
            style={{ display: 'none' }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Iniciar Novo</Button>
        <Button 
          variant="contained" 
          color="primary"
          onClick={() => fileInputRef.current.click()}
        >
          Selecionar Arquivo
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LoadXmlDialog;
