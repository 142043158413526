import React from 'react';
import { TextField } from '@mui/material';

const FormTextField = ({ id, name, label, value, onChange }) => (
  <TextField
    id={id}
    name={name}
    label={label}
    value={value}
    onChange={onChange}
    fullWidth
    margin="normal"
  />
);

export default FormTextField;
