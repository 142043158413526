import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';

const ImportCsvDialog = ({ open, onClose, onImport }) => {
  const [csvContent, setCsvContent] = useState('');

  const handleImport = () => {
    onImport(csvContent);
    setCsvContent('');
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Importar CSV</DialogTitle>
      <DialogContent>
        <TextField
          id="csvContent"
          name="csvContent"
          label="Cole o conteúdo CSV aqui"
          value={csvContent}
          onChange={(e) => setCsvContent(e.target.value)}
          multiline
          rows={10}
          fullWidth
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" variant="outlined">Cancelar</Button>
        <Button onClick={handleImport} color="primary" variant="contained">Importar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportCsvDialog;
