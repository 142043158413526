import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography } from '@mui/material';

const Clock = ({ config }) => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timer = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const formatDateTime = (date, options) => {
    const defaultOptions = {
      timeZone: config.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
      hour12: false,
    };
    const mergedOptions = { ...defaultOptions, ...options };

    try {
      return new Intl.DateTimeFormat(config.locale || navigator.language, mergedOptions).format(date);
    } catch (error) {
      console.warn('Error formatting date/time:', error);
      return new Intl.DateTimeFormat('en-US', mergedOptions).format(date);
    }
  };

  const getLocationName = (timezone) => {
    const locations = {
      'America/Sao_Paulo': 'São Paulo',
      'America/New_York': 'Nova York',
      'Europe/London': 'Londres',
      'Asia/Tokyo': 'Tóquio',
    };
    
    const resolvedTimezone = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    return locations[resolvedTimezone] || resolvedTimezone.split('/').pop().replace(/_/g, ' ');
  };

  const timeString = formatDateTime(time, { hour: '2-digit', minute: '2-digit' });
  const dateString = formatDateTime(time, { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' });

  return (
    <Card className="clock">
      <CardContent>
        <Typography variant="h6" component="div" className="clock__location">
          {getLocationName(config.timezone)}
        </Typography>
        <Typography variant="h4" component="div" className="clock__time">
          {timeString}
        </Typography>
        <Typography variant="body2" component="div" className="clock__date">
          {dateString}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Clock;
