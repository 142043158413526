import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Box
} from '@mui/material';

const NavigationMenu = ({ sections, activeSection, setActiveSection }) => (
  <Drawer
    variant="permanent"
    sx={{
      width: 240,
      flexShrink: 0,
      '& .MuiDrawer-paper': {
        width: 240,
        boxSizing: 'border-box',
        position: 'relative',
        height: '100%'
      },
    }}
  >
    <Box sx={{ p: 2, pl: 4, textAlign: 'left' }}>
      <Typography variant="h6" noWrap component="div">
        Formulário CVM
      </Typography>
    </Box>
    <Divider />
    <List>
      {sections.map((section) => (
        <ListItem 
          button 
          key={section.id}
          selected={activeSection === section.id}
          onClick={() => setActiveSection(section.id)}
          sx={{ pl: 3.5, cursor: "pointer" }}
        >
          <ListItemIcon>
            {section.icon}
          </ListItemIcon>
          <ListItemText primary={section.label} />
        </ListItem>
      ))}
    </List>
  </Drawer>
);

export default NavigationMenu;
