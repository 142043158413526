import React, { useState } from 'react';
import { select, hierarchy, tree, linkHorizontal } from 'd3';

const MarkdownMindmap = () => {
  const [markdown, setMarkdown] = useState('');

  const generateMindmap = () => {
    // Convert Markdown to JSON
    const root = hierarchy(markdownToJSON(markdown));

    // Configure the mindmap layout using D3
    const width = 800;
    const height = 600;
    const treeLayout = tree().size([height, width - 160]);
    const links = treeLayout(root).links();
    const nodes = treeLayout(root).descendants();

    // Draw the mindmap using D3
    const svg = select('#mindmap')
      .attr('width', width)
      .attr('height', height);

    // Draw the links with just a stroke
    svg.selectAll('.link')
      .data(links)
      .enter().append('path')
      .attr('class', 'link')
      .attr('fill', 'none')
      .attr('stroke', 'black')
      .attr('d', linkHorizontal()
        .x(d => d.y)
        .y(d => d.x));

    // Draw the nodes
    svg.selectAll('.node')
      .data(nodes)
      .enter().append('g')
      .attr('class', 'node')
      .attr('transform', d => `translate(${d.y}, ${d.x})`)
      .append('text')
      .attr('dx', -10)
      .attr('dy', 3)
      .text(d => d.data.name);
  };

  const markdownToJSON = (markdown) => {
    // Split the Markdown into sections (top level, Volkswagen, Fiat, Chevrolet)
    const sections = markdown.split(/^#\s/m);

    // Create the JSON object
    const root = { name: sections[1].trim(), children: [] };

    sections.slice(2).forEach((section) => {
      const lines = section.trim().split('\n');
      const sectionTitle = lines.shift().trim();
      const sectionNode = { name: sectionTitle, children: [] };

      lines.forEach((line) => {
        if (line.startsWith('-')) {
          sectionNode.children.push({ name: line.slice(2).trim() });
        }
      });

      root.children.push(sectionNode);
    });

    return root;
  };

  return (
    <div>
      <textarea
        value={markdown}
        onChange={(e) => setMarkdown(e.target.value)}
        placeholder="Enter Markdown"
      />
      <button onClick={generateMindmap}>Generate Mindmap</button>
      <svg id="mindmap"></svg>
    </div>
  );
};

export default MarkdownMindmap;