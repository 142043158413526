import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@mui/material';
import { generateXml } from '../xmlUtils';

const ViewXmlDialog = ({ open, onClose, formData }) => {
  const xmlString = generateXml(formData);
  
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>XML Atual</DialogTitle>
      <DialogContent>
        <TextField
          multiline
          fullWidth
          rows={20}
          value={xmlString}
          InputProps={{
            readOnly: true,
          }}
          variant="outlined"
          margin="normal"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Fechar</Button>
        <Button 
          onClick={() => {
            navigator.clipboard.writeText(xmlString);
            alert('XML copiado para a área de transferência!');
          }}
          color="primary"
        >
          Copiar XML
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ViewXmlDialog;
