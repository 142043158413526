import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const ClockConfig = ({ config, onChange }) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange({ ...config, [name]: value });
  };

  return (
    <div className="clock-config">
      <FormControl className="clock-config__select">
        <InputLabel>Fuso Horário</InputLabel>
        <Select
          name="timezone"
          value={config.timezone || 'America/Sao_Paulo'}
          onChange={handleChange}
        >
          <MenuItem value="America/Sao_Paulo">São Paulo</MenuItem>
          <MenuItem value="America/New_York">Nova York</MenuItem>
          <MenuItem value="Europe/London">Londres</MenuItem>
          <MenuItem value="Asia/Tokyo">Tóquio</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default ClockConfig;
