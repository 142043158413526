import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import FormTextField from '../FormTextField';

const InformacoesIniciaisSection = ({ formData, handleInputChange }) => (
  <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
    <Typography variant="h6" sx={{ mb: 2, display: 'flex', justifyContent: 'space-between'}}>Informações Iniciais</Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <FormTextField
          id="codDoc"
          name="codDoc"
          label="Código do Documento"
          value={formData.codDoc}
          onChange={handleInputChange}
        />
        <FormTextField
          id="anoBase"
          name="anoBase"
          label="Ano Base"
          value={formData.anoBase}
          onChange={handleInputChange}
        />
        <FormTextField
          id="dtGeracArq"
          name="dtGeracArq"
          label="Data de Geração do Arquivo"
          value={formData.dtGeracArq}
          onChange={handleInputChange}
        />
        <FormTextField
          id="motivoEntreg"
          name="motivoEntreg"
          label="Motivo da Entrega"
          value={formData.motivoEntreg}
          onChange={handleInputChange}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormTextField
          id="tpPfPj"
          name="tpPfPj"
          label="Tipo de Pessoa (PF/PJ)"
          value={formData.tpPfPj}
          onChange={handleInputChange}
        />
        <FormTextField
          id="nrPfPj"
          name="nrPfPj"
          label="CPF/CNPJ"
          value={formData.nrPfPj}
          onChange={handleInputChange}
        />
        <FormTextField
          id="cdCvm"
          name="cdCvm"
          label="Código CVM"
          value={formData.cdCvm}
          onChange={handleInputChange}
        />
        <FormTextField
          id="dsNome"
          name="dsNome"
          label="Nome do Auditor"
          value={formData.dsNome}
          onChange={handleInputChange}
        />
        <FormTextField
          id="dsEmail"
          name="dsEmail"
          label="E-mail do Auditor"
          value={formData.dsEmail}
          onChange={handleInputChange}
        />
      </Grid>
    </Grid>
  </Paper>
);

export default InformacoesIniciaisSection;
