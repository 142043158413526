import React, { useState, useEffect } from 'react';
import { Grid, Button, Menu, MenuItem } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ColumnConfig from './ColumnConfig';
import Clock from './../Clock/Clock';
import DocumentWidget from './../Document/DocumentWidget';

const ColumnLayout = () => {
  const [columns, setColumns] = useState([]);
  const [showConfig, setShowConfig] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  useEffect(() => {
    const savedColumns = localStorage.getItem('columnLayout');
    if (savedColumns) {
      setColumns(JSON.parse(savedColumns));
    } else {
      setColumns([
        { id: 1, width: 6, components: [] },
        { id: 2, width: 6, components: [] }
      ]);
    }
  }, []);

  const handleConfigClick = () => {
    setShowConfig(true);
  };

  const handleConfigClose = () => {
    setShowConfig(false);
  };

  const handleColumnUpdate = (updatedColumns) => {
    setColumns(updatedColumns);
    localStorage.setItem('columnLayout', JSON.stringify(updatedColumns));
    setShowConfig(false);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newColumns = [...columns];
    const sourceColumn = newColumns.find(col => col.id.toString() === result.source.droppableId);
    const destColumn = newColumns.find(col => col.id.toString() === result.destination.droppableId);

    if (sourceColumn === destColumn) {
      const [movedComponent] = sourceColumn.components.splice(result.source.index, 1);
      sourceColumn.components.splice(result.destination.index, 0, movedComponent);
    } else {
      const [movedComponent] = sourceColumn.components.splice(result.source.index, 1);
      destColumn.components.splice(result.destination.index, 0, movedComponent);
    }

    setColumns(newColumns);
    localStorage.setItem('columnLayout', JSON.stringify(newColumns));
  };

  const renderComponent = (component) => {
    switch (component.type) {
      case 'Clock':
        return <Clock key={component.id} config={component.config || {}} />;
      default:
        return null;
    }
  };

  const handleAddComponentClick = (event, columnId) => {
    setAnchorEl(event.currentTarget);
    setSelectedColumn(columnId);
  };

  const handleAddComponent = (componentType) => {
    const newColumns = columns.map(column => {
      if (column.id === selectedColumn) {
        return {
          ...column,
          components: [
            ...column.components,
            {
              id: `${componentType}-${Date.now()}`,
              type: componentType,
              config: {}
            }
          ]
        };
      }
      return column;
    });

    setColumns(newColumns);
    localStorage.setItem('columnLayout', JSON.stringify(newColumns));
    setAnchorEl(null);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="column-layout">
        <Grid container spacing={2}>
          {columns.map((column) => (
            <Grid item xs={column.width} key={column.id}>
              <Droppable droppableId={column.id.toString()}>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="column-layout__column"
                  >
                    {column.components.map((component, index) => (
                      <Draggable key={component.id} draggableId={component.id} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {renderComponent(component)}
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                    <Button onClick={(e) => handleAddComponentClick(e, column.id)}>
                      Adicionar Componente
                    </Button>
                  </div>
                )}
              </Droppable>
            </Grid>
          ))}
        </Grid>
        <Button 
          variant="contained" 
          onClick={handleConfigClick}
          className="column-layout__config-button"
        >
          Configurar Colunas
        </Button>
        {showConfig && (
          <ColumnConfig 
            columns={columns} 
            onUpdate={handleColumnUpdate} 
            onClose={handleConfigClose} 
          />
        )}
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => handleAddComponent('Clock')}>Relógio</MenuItem>
          <MenuItem onClick={() => handleAddComponent('DocumentWidget')}>DocumentWidget</MenuItem>
        </Menu>
      </div>
    </DragDropContext>
  );
};

export default ColumnLayout;
