import React from 'react';
import { Paper, Box } from '@mui/material';
import RepetitionBlock from '../../RepetitionBlock';
import FormTextField from '../FormTextField';

const EntidadesAuditadasSection = ({ 
  entidadesAuditadas, 
  qtOutraEntidAudita, 
  onAddEntidadeAuditada, 
  onRemoveEntidadeAuditada, 
  onEditEntidadeAuditada,
  handleInputChange
}) => {
  const entidadeAuditadaFields = ['tpEntid', 'nrPj', 'dsNome', 'dtContr', 'prFatura'];
  
  return (
    <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
      <RepetitionBlock
        title="Entidades Auditadas"
        items={entidadesAuditadas}
        itemFields={{ nameField: 'dsNome' }}
        editFields={entidadeAuditadaFields}
        onAddItem={onAddEntidadeAuditada}
        onRemoveItem={onRemoveEntidadeAuditada}
        onEditItem={onEditEntidadeAuditada}
      />
      <Box sx={{ mt: 3 }}>
        <FormTextField
          id="qtOutraEntidAudita"
          name="qtOutraEntidAudita"
          label="Número de Outras Entidades Auditadas"
          value={qtOutraEntidAudita}
          onChange={handleInputChange}
        />
      </Box>
    </Paper>
  );
};

export default EntidadesAuditadasSection;
