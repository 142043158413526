import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button
} from '@mui/material';

const EditItemDialog = ({ open, onClose, item, fields, onSave, title }) => {
  const [editingItem, setEditingItem] = useState({});

  useEffect(() => {
    if (item) {
      setEditingItem({ ...item }); // Faz uma cópia do item recebido para evitar mutação direta
    }
  }, [item]);

  const handleChange = (fieldKey, value) => {
    setEditingItem((prevState) => ({
      ...prevState,
      [fieldKey]: value,
    }));
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Editar {title}</DialogTitle>
      <DialogContent>
        {fields.map(({ key, label }) => (
          <TextField
            key={key}
            id={key}
            name={key}
            label={label} // Usa o label legível do mapa de tradução
            value={editingItem[key] || ''}
            onChange={(e) => handleChange(key, e.target.value)}
            fullWidth
            margin="normal"
          />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit" variant="outlined">Cancelar</Button>
        <Button 
          color="primary" 
          variant="contained" 
          onClick={() => onSave(editingItem)}
        >
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditItemDialog;
