import React, { useState } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography
} from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';

const ItemsTable = ({ items, nameField, onEdit, onRemove }) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);

  const handleOpenConfirmDialog = (item) => {
    setItemToRemove(item);
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setItemToRemove(null);
    setOpenConfirmDialog(false);
  };

  const handleConfirmRemove = () => {
    if (itemToRemove) {
      onRemove(itemToRemove); // Chama a função de remoção passada como prop
    }
    handleCloseConfirmDialog();
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="items table">
          <TableHead>
            <TableRow>
              <TableCell>Nome/Denominação</TableCell>
              <TableCell align="right">Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items && items.map((item) => (
              <TableRow key={item.id}> {/* Use o identificador único como chave */}
                <TableCell component="th" scope="row">
                  nome: {item[nameField]}
                </TableCell>
                <TableCell align="right">
                  <IconButton onClick={() => onEdit(item)} aria-label="edit" size='small' color='primary'><Edit /></IconButton>
                  <IconButton onClick={() => handleOpenConfirmDialog(item)} aria-label="delete" size='small' color='error'><Delete /></IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog de Confirmação */}
      <Dialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
      >
        <DialogTitle>Confirmar Remoção</DialogTitle>
        <DialogContent>
          <Typography>
            Tem certeza de que deseja remover este item?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} variant="outlined"
          color="inherit">
            Cancelar
          </Button>
          <Button
            onClick={handleConfirmRemove}
            color="error"
            variant="contained"
          >
            Remover
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ItemsTable;
