import React from 'react';
import { Paper } from '@mui/material';
import RepetitionBlock from '../../RepetitionBlock';

const EnderecosSection = ({ enderecos, onAddEndereco, onRemoveEndereco, onEditEndereco }) => {
  const enderecoFields = ['tpEnder', 'nmFilial', 'nmLograd', 'dsCompl', 'dsBairro', 'cdMun', 'nrCep', 'nrDddTel', 'nrTel', 'nrDddFax', 'nrFax'];
  
  return (
    <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
      <RepetitionBlock
        title="Endereços"
        items={enderecos}
        itemFields={{ nameField: 'nmFilial' }}
        editFields={enderecoFields}
        onAddItem={onAddEndereco}
        onRemoveItem={onRemoveEndereco}
        onEditItem={onEditEndereco}
      />
    </Paper>
  );
};

export default EnderecosSection;
