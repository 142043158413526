import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme, CssBaseline, useMediaQuery } from '@mui/material';
import Navbar from './Components/Navbar/Navbar';
import routes from './Config/Routes';
import { indigo, lime, grey } from '@mui/material/colors';
import './App.css';
import { useAuth } from './Config/AuthContext'; // Importar o hook de autenticação

// Funções de persistência do tema
function getPersistedMode() {
  try {
    const mode = localStorage.getItem('mode');
    if (mode && (mode === 'light' || mode === 'dark')) {
      return mode;
    } else {
      console.warn('Invalid mode found in localStorage:', mode);
      return null; // Retorna null se o modo não for válido
    }
  } catch (error) {
    console.error('Error retrieving mode from localStorage:', error);
    return null; // Retorna null em caso de erro
  }
}

function persistMode(mode) {
  localStorage.setItem('mode', mode);
}

// Função para inicializar o tema
const initializeTheme = (initialMode) => {
  return createTheme({
    palette: {
      mode: initialMode || 'light',
      background: {
        default: initialMode === 'light' ? '#eee' : '#000',
      },
      primary: {
        main: initialMode === 'light' ? indigo[500] : indigo['A700'],
      },
      secondary: {
        main: grey[500],
      },
      accent: {
        main: initialMode === 'light' ? lime[500] : indigo[100],
      },
      action: {
        active: initialMode === 'light' ? indigo[500] : indigo['A400'],
        hover: initialMode === 'light' ? indigo[300] : indigo['A700'],
        selected: initialMode === 'light' ? indigo[500] : indigo['A400'],
        disabledBackground: initialMode === 'light' ? indigo[100] : grey['A700'],
      }
    },
    typography: {
      fontFamily: '"Inter", sans-serif',
      fontSize: 13,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,

      h1: {
        fontSize: "1.6rem",
        fontFamily: '"Jost", sans-serif' // Fonte customizada para cabeçalhos h1
      },
      h2: {
        fontSize: "1.3rem",
        fontWeight: 600,
        fontFamily: '"Jost", sans-serif' // Fonte customizada para cabeçalhos h2
      },
      link: {
        color: initialMode === 'light' ? lime[500] : indigo[100],
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    components: {
      // Configurações personalizadas para componentes
      MuiCardContent: {
        styleOverrides: {
          root: {
            fontSize: 14,
            fontWeight: 400,
            letterSpacing: -0.05,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: {
            scrollbarBehavior: 'smooth',
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            lineHeight: '1.3',
          },
        },
      },
      MuiIconButton: {
        defaultProps: {
          size: 'small',
        },
        styleOverrides: {
          root: {
            transition: 'all 0.1s ease-out',
            width: '40px',
            height: '40px',
            borderRadius: '4px',

            '&:hover': {
              backgroundColor: initialMode === 'light' ? indigo[500] : indigo['A400'],
              color: 'white',
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: initialMode === 'light' ? '#fff' : '#000',
            boxShadow: 'none',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          root: {
            padding: '4px 4px 4px 8px',
            // margin: '4px 8px',
            width: '100%',
            borderRadius: '0',
            transition: 'all 0.1s ease',
            // boxShadow: '0 0 0 4px transparent inset',
            '&:hover': {
              backgroundColor: initialMode === 'light' ? indigo[50] : grey[900],
              // boxShadow: initialMode === 'light' ? `0 0 0 1px ${indigo[100]}` : `0 0 0 1px ${indigo[200]}`,
            },
            '&.Mui-selected': {
              backgroundColor: initialMode === 'light' ? '#ddd' : '#222',
            },
          },
        },
      },
      // MuiInputBase: {
      //   styleOverrides: {
      //     root: {
      //       boxSizing: 'border-box',
      //       width: '320px',
      //       fontWeight: 400,
      //       lineHeight: 1.5,
      //       padding: '12px',
      //       borderRadius: '12px',
      //       color: grey[900],
      //       background: '#fff',
      //       border: `1px solid ${grey[200]}`,
      //       boxShadow: `0px 2px 2px ${grey[50]}`,
      //       '&:hover': {
      //         borderColor: indigo[400],
      //       },
      //       '&:focus': {
      //         outline: 0,
      //         borderColor: indigo[400],
      //         boxShadow: `0 0 0 3px ${indigo[200]}`,
      //       },
      //       // Firefox specific
      //       '&:focus-visible': {
      //         outline: 0,
      //       },
      //     },
      //   },
      // },
      MuiListItemText: {
        styleOverrides: {
          root: {
            color: initialMode === 'light' ? '#000' : '#fff', // Cor do texto dos itens do menu
          },
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            color: initialMode === 'light' ? indigo[500] : indigo[200], // Cor dos ícones dos itens do menu
            minWidth: 0, // Tamanho dos ícones dos itens do menu
            marginRight: '1rem', // Margem direita dos ícones dos itens do menu
          },
        },
      },
    },
  });
};

function App() {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const { user } = useAuth();
  
  // Ajuste na inicialização do modo
  const [mode, setMode] = useState(getPersistedMode() || 'light'); // Inicia em light se não houver modo persistido

  // Função para trocar o tema
  const changeMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);
    persistMode(newMode);
  };

  // Tema baseado no estado atual
  const theme = initializeTheme(mode);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Router>
        <div className="App">
          <Navbar changeMode={changeMode} mode={mode} user={user} />
          <Routes>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.path}
                element={<route.component />}
                user={user}
                handleLogin={() => Navbar.handleLogin}
              />
            ))}
          </Routes>
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
