import React, { useState } from 'react';
import { Box, Button, Grid } from '@mui/material';

const ColumnLayout = () => {
  const [columns, setColumns] = useState([
    { id: 1, width: 4, resizable: true, content: 'Componente 1' },
    { id: 2, width: 4, resizable: true, content: 'Componente 2' },
    { id: 3, width: 4, resizable: true, content: 'Componente 3' },
  ]);

  const handleConfigureColumn = (columnId) => {
    // Lógica para abrir o modal de configuração
    console.log(`Configurar coluna ${columnId}`);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {columns.map((column) => (
          <Grid item xs={column.width} key={column.id}>
            <Box
              sx={{
                border: '1px solid #ccc',
                padding: 2,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Button
                variant="outlined"
                onClick={() => handleConfigureColumn(column.id)}
                sx={{ marginBottom: 2 }}
              >
                Configurar Coluna
              </Button>
              <Box sx={{ flexGrow: 1 }}>
                {/* Aqui será renderizado o componente específico da coluna */}
                {column.content}
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ColumnLayout;
