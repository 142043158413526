const fieldLabels = {
    codDoc: 'Código do Documento',
    anoBase: 'Ano Base',
    dtGeracArq: 'Data de Geração do Arquivo',
    motivoEntreg: 'Motivo da Entrega',
    tpPfPj: 'Tipo de Pessoa (PF/PJ)',
    nrPfPj: 'CPF/CNPJ',
    cdCvm: 'Código CVM',
    dsNome: 'Nome',
    dsEmail: 'E-mail',
    tpEnder: 'Tipo de Endereço',
    nmFilial: 'Nome da Filial',
    nmLograd: 'Logradouro',
    dsCompl: 'Complemento',
    dsBairro: 'Bairro',
    cdMun: 'Código do Município',
    nrCep: 'CEP',
    nrDddTel: 'DDD do Telefone',
    nrTel: 'Telefone',
    nrDddFax: 'DDD do Fax',
    nrFax: 'Fax',
    nrPf: 'CPF do Responsável',
    tpEntid: 'Tipo de Entidade Auditada',
    nrPj: 'CNPJ da Entidade Auditada',
    dtContr: 'Data do Contrato',
    prFatura: 'Valor da Fatura',
    qtOutraEntidAudita: 'Quantidade de Outras Entidades Auditadas',
    vlAudit: 'Valor Total de Auditoria',
    prAudit: '% da Auditoria no Total de Receitas',
    hhAudit: 'Horas Trabalhadas na Auditoria',
    dsCriterHon: 'Critérios de Honorários',
    qtSocios: 'Quantidade de Sócios na Empresa de Auditoria',
    qtFuncTecno: 'Quantidade de Funcionários Técnicos na Empresa de Auditoria',
    stEstr: 'Estrutura Organizacional da Entidade Coligada',
    dsSetorAtivd: 'Setor de Atividade da Entidade Coligada'
  };
  
  export default fieldLabels;
  