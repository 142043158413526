import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Snackbar,
  Alert
} from '@mui/material';
import {
  Info,
  LocationOn,
  Person,
  Business,
  Link
} from '@mui/icons-material';

import LoadXmlDialog from './LoadXmlDialog';
import BottomBar from './BottomBar';
import ViewXmlDialog from './parts/ViewXmlDialog';
import { saveXmlFile, parseXmlToFormData } from './xmlUtils';

// Importando os componentes das seções
import NavigationMenu from './parts/NavigationMenu';
import InformacoesIniciaisSection from './parts/sections/InformacoesIniciaisSection';
import EnderecosSection from './parts/sections/EnderecosSection';
import ResponsaveisSection from './parts/sections/ResponsaveisSection';
import EntidadesAuditadasSection from './parts/sections/EntidadesAuditadasSection';
import EntidadesColigadasSection from './parts/sections/EntidadesColigadasSection';

const AuditorForm = () => {
  const [formData, setFormData] = useState({
    codDoc: '',
    anoBase: '',
    dtGeracArq: '',
    motivoEntreg: '',
    tpPfPj: '',
    nrPfPj: '',
    cdCvm: '',
    dsNome: '',
    dsEmail: '',
    enderecos: [], // Inicializa como array vazio
    responsaveis: [], // Inicializa como array vazio
    entidadesAuditadas: [], // Inicializa como array vazio
    qtOutraEntidAudita: '',
    vlAudit: '',
    prAudit: '',
    hhAudit: '',
    dsCriterHon: '',
    qtSocios: '',
    qtFuncTecno: '',
    entidadesColigadas: [] // Inicializa como array vazio
  });

  const [activeSection, setActiveSection] = useState('info');
  const [openLoadDialog, setOpenLoadDialog] = useState(true);
  const [openViewXmlDialog, setOpenViewXmlDialog] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('info');
  const fileInputRef = useRef(null);

  // Definição das seções do menu
  const sections = [
    { id: 'info', label: 'Informações Iniciais', icon: <Info /> },
    { id: 'enderecos', label: 'Endereços', icon: <LocationOn /> },
    { id: 'responsaveis', label: 'Responsáveis', icon: <Person /> },
    { id: 'entidadesAuditadas', label: 'Entidades Auditadas', icon: <Business /> },
    { id: 'entidadesColigadas', label: 'Entidades Coligadas', icon: <Link /> },
  ];

  useEffect(() => {
    // Exibir alerta inicial
    setSnackbarMessage('Lembre-se de salvar o arquivo XML periodicamente para não perder seus dados!');
    setSnackbarOpen(true);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // Funções genéricas para manipular arrays no estado
  const handleEditItem = (key, updatedItem) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key].map((item) =>
        item.id === updatedItem.id ? updatedItem : item // Usa identificador único para comparar e atualizar o item correto
      ),
    }));
  };
  
  const handleAddItem = (key, newItems) => {
    const newItemsWithId = Array.isArray(newItems)
      ? newItems.map((item) => ({ ...item, id: Date.now() })) // Adiciona identificador único
      : { ...newItems, id: Date.now() }; // Adiciona identificador único para um único item
  
    setFormData((prevState) => ({
      ...prevState,
      [key]: Array.isArray(newItemsWithId)
        ? [...prevState[key], ...newItemsWithId]
        : [...prevState[key], newItemsWithId],
    }));
  };
  
  const handleRemoveItem = (key, itemToRemove) => {
    setFormData((prevState) => ({
      ...prevState,
      [key]: prevState[key].filter((item) => item.id !== itemToRemove.id), // Compara pelo identificador único
    }));
  };
  
  

  const handleSaveXml = () => {
    const success = saveXmlFile(formData);
    if (success) {
      setSnackbarMessage('Arquivo XML salvo com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    }
  };

  const handleLoadXml = (xmlContent) => {
    try {
      const parsedData = parseXmlToFormData(xmlContent, formData);
      setFormData(parsedData);
      setOpenLoadDialog(false);

      setSnackbarMessage('Arquivo XML carregado com sucesso!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage('Erro ao carregar o arquivo XML.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleViewXml = () => {
    setOpenViewXmlDialog(true);
  };

  // Renderiza a seção ativa do formulário
  const renderActiveSection = () => {
    switch (activeSection) {
      case 'info':
        return (
          <InformacoesIniciaisSection
            formData={formData}
            handleInputChange={handleInputChange}
          />
        );
      case 'enderecos':
        return (
          <EnderecosSection
            enderecos={formData.enderecos}
            onAddEndereco={(item) => handleAddItem('enderecos', item)}
            onRemoveEndereco={(item) => handleRemoveItem('enderecos', item)}
            onEditEndereco={(item) => handleEditItem('enderecos', item)}
          />
        );
      case 'responsaveis':
        return (
          <ResponsaveisSection
            responsaveis={formData.responsaveis}
            onAddResponsavel={(item) => handleAddItem('responsaveis', item)}
            onRemoveResponsavel={(item) =>
              handleRemoveItem('responsaveis', item)
            }
            onEditResponsavel={(item) =>
              handleEditItem('responsaveis', item)
            }
          />
        );
      case 'entidadesAuditadas':
        return (
          <EntidadesAuditadasSection
            entidadesAuditadas={formData.entidadesAuditadas}
            qtOutraEntidAudita={formData.qtOutraEntidAudita}
            onAddEntidadeAuditada={(item) =>
              handleAddItem('entidadesAuditadas', item)
            }
            onRemoveEntidadeAuditada={(item) =>
              handleRemoveItem('entidadesAuditadas', item)
            }
            onEditEntidadeAuditada={(item) =>
              handleEditItem('entidadesAuditadas', item)
            }
            handleInputChange={handleInputChange}
          />
        );
      case 'entidadesColigadas':
        return (
          <EntidadesColigadasSection
            entidadesColigadas={formData.entidadesColigadas}
            onAddEntidadeColigada={(item) =>
              handleAddItem('entidadesColigadas', item)
            }
            onRemoveEntidadeColigada={(item) =>
              handleRemoveItem('entidadesColigadas', item)
            }
            onEditEntidadeColigada={(item) =>
              handleEditItem('entidadesColigadas', item)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box
      id="form-container"
      sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}
    >
      {/* Conteúdo principal */}
      <Box sx={{ display: 'flex', flexGrow: 1, overflowY:'auto'}}>
        
       {/* Navegação */}
       <NavigationMenu 
         sections={sections}
         activeSection={activeSection}
         setActiveSection={setActiveSection}/>

        {/* Área do formulário */}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: .5,
            overflowY: 'auto',
            maxHeight: 'calc( 100vh - 128px)'
          }}
        >
          {renderActiveSection()}
        </Box>
      </Box>

      {/* Barra inferior */}
      <BottomBar
        onSave={handleSaveXml}
        onOpenFileSelector={handleLoadXml}
        fileInputRef={fileInputRef}
        onViewXml={handleViewXml}
      />

      {/* Dialogs */}
      <LoadXmlDialog
        open={openLoadDialog}
        onClose={() => setOpenLoadDialog(false)}
        onLoad={handleLoadXml}
      />

      <ViewXmlDialog
        open={openViewXmlDialog}
        onClose={() => setOpenViewXmlDialog(false)}
        formData={formData}
      />

      {/* Snackbar */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
          onClose={() => setSnackbarOpen(false)}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default AuditorForm;
