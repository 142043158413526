import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, IconButton, Menu, MenuItem } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import ClockConfig from '../Clock/ClockConfig';
import DocumentWidget from '../Document/DocumentWidget';

const ColumnConfig = ({ columns, onUpdate, onClose }) => {
  const [configColumns, setConfigColumns] = useState(columns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);

  const handleWidthChange = (id, width) => {
    const updatedColumns = configColumns.map(col =>
      col.id === id ? { ...col, width: parseInt(width) } : col
    );
    setConfigColumns(updatedColumns);
  };

  const handleAddComponentClick = (event, columnId) => {
    setAnchorEl(event.currentTarget);
    setSelectedColumn(columnId);
  };

  const handleAddComponent = (componentType) => {
    const updatedColumns = configColumns.map(col =>
      col.id === selectedColumn ? {
        ...col,
        components: [
          ...col.components,
          { id: `${componentType}-${Date.now()}`, type: componentType, config: {} }
        ]
      } : col
    );
    setConfigColumns(updatedColumns);
    setAnchorEl(null);
  };

  const handleComponentChange = (columnId, componentId, newConfig) => {
    const updatedColumns = configColumns.map(col =>
      col.id === columnId ? {
        ...col,
        components: col.components.map(comp =>
          comp.id === componentId ? { ...comp, config: newConfig } : comp
        )
      } : col
    );
    setConfigColumns(updatedColumns);
  };

  const handleRemoveComponent = (columnId, componentId) => {
    const updatedColumns = configColumns.map(col =>
      col.id === columnId ? {
        ...col,
        components: col.components.filter(comp => comp.id !== componentId)
      } : col
    );
    setConfigColumns(updatedColumns);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const columnId = parseInt(result.source.droppableId);
    const column = configColumns.find(col => col.id === columnId);

    const reorderedComponents = [...column.components];
    const [movedComponent] = reorderedComponents.splice(result.source.index, 1);
    reorderedComponents.splice(result.destination.index, 0, movedComponent);

    setConfigColumns(configColumns.map(col =>
      col.id === columnId ? { ...col, components: reorderedComponents } : col
    ));
  };

  const handleSave = () => {
    onUpdate(configColumns);
  };

  const renderComponentConfig = (component, columnId) => {
    switch (component.type) {
      case 'Clock':
        return (
          <ClockConfig
            config={component.config}
            onChange={(newConfig) =>
              handleComponentChange(columnId, component.id, newConfig)
            }
          />
        );
      // Adicione casos para outros tipos de componentes aqui
      default:
        return null;
    }
  };

  return (
    <Dialog open={true} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Configurar Colunas</DialogTitle>
      <DialogContent>
        <DragDropContext onDragEnd={onDragEnd}>
          {configColumns.map((column) => (
            <Droppable droppableId={column.id.toString()} key={column.id}>
              {(provided) => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                  <TextField
                    label={`Largura da Coluna ${column.id}`}
                    type="number"
                    value={column.width}
                    onChange={(e) => handleWidthChange(column.id, e.target.value)}
                  />
                  {column.components.map((component, index) => (
                    <Draggable key={component.id} draggableId={component.id} index={index}>
                      {(provided) => (
                        <div 
                          ref={provided.innerRef} 
                          {...provided.draggableProps} 
                          style={{
                            ...provided.draggableProps.style,
                            marginBottom: '10px',
                            padding: '10px',
                            border: '1px solid #ccc',
                            borderRadius: '4px'
                          }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                            <IconButton {...provided.dragHandleProps}>
                              <DragIndicatorIcon />
                            </IconButton>
                            <Typography variant="subtitle1" style={{ flexGrow: 1 }}>{component.type}</Typography>
                            <IconButton onClick={() => handleRemoveComponent(column.id, component.id)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                          {renderComponentConfig(component, column.id)}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <Button 
                    startIcon={<AddIcon />}
                    onClick={(e) => handleAddComponentClick(e, column.id)}
                  >
                    Adicionar Componente
                  </Button>
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSave}>Salvar</Button>
      </DialogActions>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem onClick={() => handleAddComponent('Clock')}>Relógio</MenuItem>
        <MenuItem onClick={() => handleAddComponent('DocumentWidget')}>DocumentWidget</MenuItem>
        {/* Adicione mais opções de componentes aqui DocumentWidget */}
      </Menu>
    </Dialog>
  );
};

export default ColumnConfig;
