import React from 'react';
import { Paper } from '@mui/material';
import RepetitionBlock from '../../RepetitionBlock';
import fieldLabels from '../fieldLabels';

const ResponsaveisSection = ({ responsaveis, onAddResponsavel, onRemoveResponsavel, onEditResponsavel }) => {
  const responsavelFields = ['nrPf', 'dsNome', 'dsEmail'];

  return (
    <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
      <RepetitionBlock
        title="Responsáveis"
        items={responsaveis}
        itemFields={{ nameField: 'dsNome' }}
        editFields={responsavelFields}
        onAddItem={(newItem) => {
          const newItemWithId = { ...newItem, id: Date.now() }; // Adiciona um identificador único
          onAddResponsavel(newItemWithId);
        }}
        onRemoveItem={(itemToRemove) => {
          if (itemToRemove) {
            onRemoveResponsavel(itemToRemove);
          }
        }}
        onEditItem={(updatedItem) => {
          if (updatedItem) {
            onEditResponsavel(updatedItem);
          }
        }}
      />
    </Paper>
  );
};

export default ResponsaveisSection;
