import React from 'react';
import { Paper } from '@mui/material';
import RepetitionBlock from '../../RepetitionBlock';

const EntidadesColigadasSection = ({ 
  entidadesColigadas, 
  onAddEntidadeColigada, 
  onRemoveEntidadeColigada, 
  onEditEntidadeColigada 
}) => {
  const entidadeColigadaFields = ['stEstr', 'nrPj', 'dsNome', 'dsSetorAtivd'];
  
  return (
    <Paper elevation={2} sx={{ p: 3, mb: 2 }}>
      <RepetitionBlock
        title="Entidades Coligadas"
        items={entidadesColigadas}
        itemFields={{ nameField: 'dsNome' }}
        editFields={entidadeColigadaFields}
        onAddItem={onAddEntidadeColigada}
        onRemoveItem={onRemoveEntidadeColigada}
        onEditItem={onEditEntidadeColigada}
      />
    </Paper>
  );
};

export default EntidadesColigadasSection;
