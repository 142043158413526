import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Paper,
} from '@mui/material';
import EditItemDialog from './EditItemDialog';
import ImportCsvDialog from './ImportCsvDialog';
import ItemsTable from './ItemsTable';
import fieldLabels from './parts/fieldLabels'; // Importa o mapa de tradução

const RepetitionBlock = ({
  title,
  items = [],
  itemFields,
  editFields = [],
  onAddItem,
  onRemoveItem,
  onEditItem,
}) => {
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editingItem, setEditingItem] = useState({});
  const [openImportDialog, setOpenImportDialog] = useState(false);

  const handleEditItem = (item) => {
    setEditingItem({ ...item }); // Faz uma cópia do item para evitar mutação direta
    setOpenEditDialog(true);
  };

  const handleSaveEdit = (updatedItem) => {
    onEditItem(updatedItem); // Propaga o item atualizado ao componente pai
    setOpenEditDialog(false);
    setEditingItem({});
  };

  const handleAddEmptyItem = () => {
    const emptyItem = {};
    editFields.forEach((field) => {
      emptyItem[field] = '';
    });

    if (itemFields && itemFields.nameField) {
      emptyItem[itemFields.nameField] = `Novo ${title.slice(0, -1)}`;
    }

    emptyItem.id = Date.now(); // Adiciona um identificador único
    onAddItem(emptyItem);
  };

  const handleImportCsv = (csvContent) => {
    const csvLines = csvContent.split('\n').filter(line => line.trim() !== '');
    const newItems = csvLines.slice(1).map((line) => {
      const fields = line.split(',');
      const newItem = {};
      editFields.forEach((field, index) => {
        if (index < fields.length) {
          newItem[field] = fields[index] || ''; // Garantir que não seja undefined
        } else {
          newItem[field] = ''; // Valor padrão para campos ausentes
        }
      });
      newItem.id = Date.now(); // Adiciona identificador único
      return newItem;
    });

    onAddItem(newItems);
    setOpenImportDialog(false);
  };

  return (
    <Box sx={{ mb: 2 }}>
      <Typography variant="h6" sx={{ mb: 1, display: 'flex', justifyContent: 'space-between' }}>
        {title}
        <div>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
            onClick={handleAddEmptyItem}
          >
            Adicionar
          </Button>
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
            onClick={() => setOpenImportDialog(true)}
          >
            Importar CSV
          </Button>
        </div>
      </Typography>

      {/* Verifica se há itens na lista */}
      {items.length === 0 ? (
        <Paper
          elevation={1}
          sx={{
            p: 2,
            textAlign: 'center',
            border: '1px dashed #ccc',
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '50vh',
            mt: 2,
          }}
        >
          <Typography variant="body1" color="textSecondary">
            Adicione novos itens ou Importe um arquivo .CSV
          </Typography>
        </Paper>
      ) : (
        <ItemsTable 
          items={items}
          nameField={itemFields.nameField}
          onEdit={handleEditItem}
          onRemove={onRemoveItem}
        />
      )}

      <EditItemDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        item={editingItem}
        fields={editFields.map((field) => ({
          key: field,
          label: fieldLabels[field], // Usa o mapa para exibir nomes legíveis
        }))}
        onSave={handleSaveEdit}
        title={title}
      />

      <ImportCsvDialog
        open={openImportDialog}
        onClose={() => setOpenImportDialog(false)}
        onImport={handleImportCsv}
      />
    </Box>
  );
};

export default RepetitionBlock;
