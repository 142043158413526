import React from 'react';
import {
  AppBar,
  Toolbar,
  Button
} from '@mui/material';
import { Save, Upload, Visibility } from '@mui/icons-material';

const BottomBar = ({ onSave, onOpenFileSelector, fileInputRef, onViewXml }) => {
  return (
    <AppBar 
      position="static" 
      color="default" 
      sx={{ 
        top: 'auto', 
        bottom: 0,
        boxShadow: '0px -2px 4px rgba(0,0,0,0.1)'
      }}
    >
      <Toolbar sx={{ justifyContent: 'flex-end', gap: 2 }}>
        <input
          type="file"
          ref={fileInputRef}
          onChange={(e) => {
            const file = e.target.files[0];
            if (file) {
              const reader = new FileReader();
              reader.onload = (event) => {
                const xmlContent = event.target.result;
                onOpenFileSelector(xmlContent);
              };
              reader.readAsText(file);
            }
          }}
          accept=".xml"
          style={{ display: 'none' }}
        />
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Visibility />}
          onClick={onViewXml}
        >
          Ver XML Atual
        </Button>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<Upload />}
          onClick={() => fileInputRef.current.click()}
        >
          Abrir Arquivo XML
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={onSave}
        >
          Salvar Novo XML
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default BottomBar;
